<template>
    <v-container>
      <v-row>
        <v-col md="12">
          <v-toolbar :dark="$store.state.isDarkMode">
            <template>
              <v-tabs v-model="tab" align-with-title color="#757575">
                <v-tabs-slider></v-tabs-slider>
                <v-col md="1"></v-col>
                <v-col md="2" >
                  <v-tab :href="'#' + '1'">{{
                    $t("students")
                  }}</v-tab>
                </v-col>
                <v-col md="2">
                  <v-tab :href="'#' + '2'">
                    {{ $t("teachers") }}
                  </v-tab>
                </v-col>
                <v-col md="2">
                  <v-tab :href="'#' + '3'">
                    {{ $t("subjects") }}
                  </v-tab>
                </v-col>
                <v-col md="2">
                  <v-tab :href="'#' + '4'">
                    {{ $t("calsses") }}
                  </v-tab>
                </v-col>
                <v-col md="2">
                  <v-tab :href="'#' + '5'">
                    {{ $t("education class") }}
                  </v-tab>
                </v-col>
              </v-tabs>
            </template>
          </v-toolbar>
        </v-col>
      </v-row>
      <v-tabs-items :dark="$store.state.isDarkMode" v-model="tab">
        <v-tab-item value="1">
          <studentDashbord v-if="tab == '1'" />
        </v-tab-item>
        <v-tab-item value="2">
          <teachersDashbord v-if="tab == '2'" />
        </v-tab-item>
        <v-tab-item value="3">
          <subjectsDashbord v-if="tab == '3'" />
        </v-tab-item>
        <v-tab-item value="4">
          <calssesDashbord v-if="tab == '4'" />
        </v-tab-item>
        <v-tab-item value="5">
          <educationClassDashbord v-if="tab == '5'" />
        </v-tab-item>
      </v-tabs-items>
    </v-container>
</template>
  
<script>
import studentDashbord from "./studentDashbord.vue";
import teachersDashbord from "./teachersDashbord.vue";
import subjectsDashbord from "./subjectsDashbord.vue";
import calssesDashbord from "./calssesDashbord.vue";
import educationClassDashbord from "./educationClassDashbord.vue";
  export default {
    components: {
        studentDashbord,
        teachersDashbord,
        subjectsDashbord,
        calssesDashbord,
        educationClassDashbord,
    },
    data() {
      return {
        tab: false,
      };
    },
  };
</script>
  
<style>
  .registration-record__img-wrapper {
    width: 150px;
    height: auto;
    cursor: pointer;
  }
  
  .registration-record__img {
    width: 100%;
  }
</style>
  